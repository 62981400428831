<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('orderCanceledInvoiceFilter')"
                  >
                    <CIcon size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CRow form class="form-group">
                <CCol tag="label" sm="4" class="col-form-label">
                  <span>Sadece Bildirilmemişleri Göster</span>
                </CCol>
                <CCol sm="2" style="margin-left: -15px">
                  <CSwitch
                    class="mr-1"
                    size="lg"
                    color="primary"
                    :value.sync="onlyUnReported"
                    @update:checked="(item) => setOnlyUnReported(item)"
                  />
                </CCol>
              </CRow>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="invoices"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            v-on:refresh="filterInvoices(filterParams)"
          >
            <template #show_details="{item, index}">
              <td class="py-2">
                <CButton color="danger" square
                         size="sm"
                         @click="openModalEvent('confirm', 'confirm', item, 'Onay', item.id+' Seçtiğiniz faturayı bildirmek istediğinizden emin misin?')"
                         :disabled="item.informedCancelledFlag"
                >
                  İptal Bildirimi Yap
                </CButton>
              </td>
            </template>
            <template #informedCancelledFlag="{item}">
              <td>
                <CIcon v-if="item.informedCancelledFlag" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #createTime="{item}">
              <td> {{ dateFormat(item.createTime) }}</td>
            </template>
            <template #informedCancelledTime="{item}">
              <td> {{ item.informedCancelledTime && dateFormat(item.informedCancelledTime) }}</td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="operationEvent"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterInvoices"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>
<script>
import moment from 'moment';
import FilterSidebar from '../components/filterSidebar'
import ConfirmModal from '../components/confirmModal'
import { prepareFilterParams } from "@/helpers/custom"


export default {
  name: 'CanceledInvoices',
  components:{ FilterSidebar, ConfirmModal },
  data() {
    return {
      onlyUnReported: false,
      modalTitle: '',
      actionType: '',
      module: 'orders',
      form: 'orderAlertLogDetail',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openSidebar: false,
      filterForm: 'orderCanceledInvoiceFilter',
      filterParams: {},
      lastItem: {},
      activePage: 1,
      itemsPerPage: 10,
      fields: [
        {key: 'id', label: 'Fatura No', _style: 'font-size:12px'},
        {key: 'orderId', label: 'Sipariş No',_style: 'font-size:12px'},
        {key: 'createTime', label: 'Fatura Tarihi',_style: 'font-size:12px'},
        {key: 'informedCancelledFlag', label: 'F. İptal Durumu',_style: 'font-size:12px'},
        {key: 'informedCancelledTime', label: 'F. İptal Tarihi',_style: 'font-size:12px'},
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        }
      ],
      filters: [{
        type: 'string',
        dataIndex: 'id',
        field: 'id',
        comparison: 'eq'
      }, {
        type: 'numeric',
        dataIndex: 'orderId',
        field: 'orderId',
        comparison: 'eq'
      }, {
        type: 'boolean',
        dataIndex: 'informedCancelledFlag',
        field: 'informedCancelledFlag'
      },   {
        type: 'date',
        dataIndex: 'startTime',
        field: 'createTime',
        comparison: 'gt'
      }, {
          type: 'date',
          dataIndex: 'endTime',
          field: 'createTime',
          comparison: 'gt'
      }]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    invoices () {
      return this.$store.getters.orderCanceledInvoices;
    },
    loading () {
      return this.$store.getters.orderLoading;
    }
  },
  methods: {
    async openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    dateFormat(date){
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    rowClicked(item, index) {
      this.lastItem = item;
    },

    async operationEvent(item, action){
      await this.$store.dispatch('order_updateCanceledInvoice', {invoiceIds: item.id})
      if(this.$store.getters.orderStatus.success){
        this.filterInvoices(this.filterParams)
        this.openModalEvent()
      }
    },
    async setOnlyUnReported(item) {
      this.$store.dispatch('order_canceledInvoices', {params: {onlyUnreported: item}})
    },
    async filterInvoices(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      await this.$store.dispatch('order_canceledInvoices', formData)
      
      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
  },
  created() {
    this.filterInvoices(this.filterParams)
  }
}
</script>
